import Navigation from "../components/Navigation";
import { Footer } from "../components/sections/Footer";
import { usePricingTiers } from "@/hooks/usePricingTiers";
import PricingHeader from "@/components/pricing/PricingHeader";
import PricingGrid from "@/components/pricing/PricingGrid";
import PricingLoadingSkeleton from "@/components/pricing/PricingLoadingSkeleton";

const Pricing = () => {
  const { data: pricingTiers, isLoading } = usePricingTiers();

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white">
        <Navigation />
        <PricingLoadingSkeleton />
      </div>
    );
  }

  if (!pricingTiers || pricingTiers.length === 0) {
    console.log('No pricing tiers found');
    return null;
  }

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
        <PricingHeader />
        <PricingGrid pricingTiers={pricingTiers} />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;