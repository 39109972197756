import { Link } from "react-router-dom";
import NavLink from "./NavLink";
import { LogIn } from "lucide-react";

interface DesktopNavProps {
  scrollToSection: (id: string) => void;
}

const DesktopNav = ({ scrollToSection }: DesktopNavProps) => {
  return (
    <div className="hidden md:flex items-center justify-between w-full">
      {/* Left section - empty for logo spacing */}
      <div />

      {/* Center section - navigation links with absolute positioning */}
      <div className="absolute left-1/2 -translate-x-1/2">
        <div className="bg-primary/[0.02] border border-primary/[0.05] rounded-full p-1 flex items-center">
          <button
            onClick={() => scrollToSection("features")}
            className="px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
          >
            Features
          </button>
          <button
            onClick={() => scrollToSection("faq")}
            className="px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
          >
            FAQ
          </button>
          <NavLink href="/pricing">Pricing</NavLink>
          <NavLink href="/blog">Blog</NavLink>
        </div>
      </div>

      {/* Right section - auth buttons */}
      <div className="flex items-center gap-3">
        <a 
          href="https://app.propertyvue.io/auth"
          className="flex items-center gap-2 px-6 py-2.5 rounded-full text-primary hover:bg-primary/5 transition-all duration-200"
        >
          <LogIn size={18} />
          <span>Sign In</span>
        </a>
        <Link to="/pricing">
          <button className="bg-primary text-white px-6 py-2.5 rounded-full hover:bg-white hover:text-primary border-2 border-primary transition-all duration-200">
            Get Started
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DesktopNav;