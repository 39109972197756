import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/sanity";
import Navigation from "@/components/Navigation";
import { Footer } from "@/components/sections/Footer";
import BlogPostSkeleton from "@/components/blog/BlogPostSkeleton";
import BlogPostContent from "@/components/blog/BlogPostContent";

interface BlogPost {
  title: string;
  publishedAt: string;
  image?: {
    asset: {
      _ref: string;
    };
  };
  body: any[];
}

const BlogPost = () => {
  const { slug } = useParams();

  const { data: post, isLoading, error } = useQuery({
    queryKey: ['blog-post', slug],
    queryFn: async () => {
      console.log('Fetching blog post with slug:', slug);
      const result = await client.fetch<BlogPost>(`
        *[_type == "post" && slug.current == $slug][0] {
          title,
          publishedAt,
          image,
          "body": body[] {
            ...,
            children[] {
              ...,
              _type,
              marks,
              text
            }
          }
        }
      `, { slug });
      console.log('Raw Sanity response:', JSON.stringify(result, null, 2));
      console.log('Body structure:', result?.body ? JSON.stringify(result.body[0], null, 2) : 'No body');
      return result;
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      <main className="pt-40 pb-20 px-4 sm:px-6 lg:px-8"> {/* Increased top padding from py-12 to pt-40 */}
        <div className="container mx-auto">
          {isLoading ? (
            <BlogPostSkeleton />
          ) : error ? (
            <div className="text-center text-red-500">
              Error loading blog post. Please try again later.
            </div>
          ) : post ? (
            <BlogPostContent {...post} />
          ) : (
            <div className="text-center text-gray-500">
              Blog post not found
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogPost;