import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { urlFor } from "@/lib/sanity";

interface BlogPost {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  publishedAt: string;
  image?: {
    asset: {
      _ref: string;
    };
  };
  body: Array<{
    children: Array<{
      text: string;
    }>;
  }>;
}

interface BlogCardProps {
  post: BlogPost;
}

const BlogCard = ({ post }: BlogCardProps) => {
  return (
    <Link to={`/blog/${post.slug.current}`}>
      <Card className="hover:shadow-lg transition-shadow h-full">
        {post.image && (
          <div className="relative w-full h-48 overflow-hidden">
            <img
              src={urlFor(post.image)
                .width(800)
                .height(600)
                .quality(90)
                .fit('crop')
                .crop('entropy')
                .url()}
              alt={post.title}
              className="object-cover w-full h-full"
            />
          </div>
        )}
        <CardHeader>
          <CardTitle>{post.title}</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-gray-600 line-clamp-3">
            {post.body?.[0]?.children?.[0]?.text || 'No preview available'}
          </p>
          <p className="text-sm text-gray-400 mt-4">
            {new Date(post.publishedAt).toLocaleDateString()}
          </p>
        </CardContent>
      </Card>
    </Link>
  );
};

export default BlogCard;