import { motion } from "framer-motion";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import confetti from "canvas-confetti";

export const TaskDemo = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const dueDate = new Date(1776, 6, 4);

  useEffect(() => {
    const fetchImage = async () => {
      console.log("Fetching task image...");
      setIsLoading(true);
      try {
        const { data, error } = await supabase.storage
          .from('media')
          .createSignedUrl('taskImage1.png', 60 * 60);
        
        if (error) {
          console.error("Error fetching task image:", error);
          return;
        }
        
        if (data?.signedUrl) {
          console.log("Successfully got signed URL for task image");
          setImageUrl(data.signedUrl);
        }
      } catch (error) {
        console.error("Error in fetchImage:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchImage();
  }, []);

  const handleComplete = () => {
    setIsComplete(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.8 }
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className="relative bg-white rounded-lg shadow-lg p-6"
    >
      <div className="flex flex-col space-y-6">
        <div className="flex items-center justify-between gap-8">
          <div className="flex flex-col items-start text-left space-y-3 flex-1">
            <h3 className="text-2xl font-semibold text-gray-900">Utility Pipe Inspection</h3>
            <p className="text-base text-gray-600">Inspect the cleared path and verify trench and pipe locations</p>
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-primary/10 text-primary">
              Palm Grove Renovation
            </span>
          </div>
          <div className="w-24 h-24 flex-shrink-0 overflow-hidden rounded-xl self-center">
            {isLoading ? (
              <div className="w-full h-full animate-pulse bg-gray-200 rounded-xl" />
            ) : (
              <img
                src={imageUrl || "/placeholder.svg"}
                alt="Task reference"
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.error("Error loading image, falling back to placeholder");
                  e.currentTarget.src = "/placeholder.svg";
                }}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between items-center pt-4 border-t border-gray-100">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-red-600 font-medium">
              Due: {format(dueDate, 'MMMM d, yyyy')}
            </span>
            <span className="px-2 py-0.5 text-xs font-medium bg-red-50 text-red-600 rounded-full">
              Overdue
            </span>
          </div>
          <div className="relative flex-shrink-0">
            <button
              onClick={handleComplete}
              disabled={isComplete}
              className={`relative z-10 px-4 py-1.5 rounded-full text-sm transition-colors ${
                isComplete 
                  ? "bg-green-100 text-green-800"
                  : "bg-gray-100 text-gray-700 hover:bg-green-50 hover:text-green-700"
              }`}
            >
              {isComplete ? "Completed" : "Mark Complete"}
            </button>
            {!isComplete && (
              <div className="absolute -inset-4 z-0">
                <div className="absolute inset-0 animate-pulse-scale rounded-full bg-green-600/30" />
                <div className="absolute inset-0 animate-pulse-scale delay-150 rounded-full bg-green-600/20" />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};