import { motion } from "framer-motion";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

const usernames = [
  "Christopher Godfrey",
  "Emma Richardson",
  "Michael Chen",
  "Sarah Williams",
  "James Anderson",
  "Maria Garcia",
  "David Kim",
  "Rachel Thompson"
];

interface CommentPinProps {
  id: number;
  x: number;
  y: number;
  text: string;
}

export const CommentPin = ({ id, x, y, text }: CommentPinProps) => {
  const username = usernames[id % usernames.length];

  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <HoverCardTrigger asChild>
        <button
          className="absolute group transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
          style={{ left: `${x}%`, top: `${y}%` }}
        >
          <div className="relative">
            {/* Smooth pulsing rings with gradient */}
            <div className="absolute -inset-4 rounded-full animate-pulse-ring bg-gradient-to-r from-blue-500/50 to-white/30" />
            <div className="absolute -inset-4 rounded-full animate-pulse-ring delay-1000 bg-gradient-to-r from-blue-500/40 to-white/20" />
            
            {/* Solid inner circle */}
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ 
                type: "spring", 
                stiffness: 400, 
                damping: 10,
                duration: 0.2 
              }}
              className="relative bg-blue-500 rounded-full p-1 flex items-center justify-center w-6 h-6 shadow-lg"
            >
              <span className="text-white text-xs font-bold">{id}</span>
            </motion.div>
          </div>
        </button>
      </HoverCardTrigger>
      <HoverCardContent 
        className="w-64 p-4 bg-white shadow-lg border border-gray-200"
        sideOffset={5}
      >
        <div className="flex items-center gap-3 mb-2">
          <div className="flex-shrink-0 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
            <span className="text-white text-xs font-bold">{id}</span>
          </div>
          <span className="font-semibold text-gray-900">{username}</span>
        </div>
        <p className="text-sm text-gray-600">{text}</p>
      </HoverCardContent>
    </HoverCard>
  );
};