import { motion } from "framer-motion";

const PricingLoadingSkeleton = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="pt-12 pb-20 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto">
        <div className="animate-pulse space-y-8">
          <div className="h-12 bg-gray-200 rounded w-3/4 mx-auto" />
          <div className="h-8 bg-gray-200 rounded w-1/2 mx-auto" />
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-96 bg-gray-200 rounded-2xl" />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PricingLoadingSkeleton;