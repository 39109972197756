import { Link } from "react-router-dom";
import { LogIn } from "lucide-react";

interface MobileNavProps {
  isOpen: boolean;
  scrollToSection: (id: string) => void;
  onClose: () => void;
}

const MobileNav = ({ isOpen, scrollToSection, onClose }: MobileNavProps) => {
  if (!isOpen) return null;

  return (
    <div className="md:hidden fixed inset-x-0 top-24 bg-white/95 backdrop-blur-md shadow-lg">
      <div className="px-4 py-6 space-y-4">
        <div className="space-y-2">
          <button
            onClick={() => {
              scrollToSection("features");
              onClose();
            }}
            className="w-full text-left px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
          >
            Features
          </button>
          <button
            onClick={() => {
              scrollToSection("faq");
              onClose();
            }}
            className="w-full text-left px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
          >
            FAQ
          </button>
          <Link
            to="/pricing"
            className="block px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
            onClick={onClose}
          >
            Pricing
          </Link>
          <Link
            to="/blog"
            className="block px-4 py-2 rounded-full text-primary/70 hover:bg-primary/10 transition-all duration-200"
            onClick={onClose}
          >
            Blog
          </Link>
        </div>
        <div className="space-y-3 pt-4 border-t border-gray-100">
          <a
            href="https://app.propertyvue.io/auth"
            className="flex items-center justify-center gap-2 w-full px-6 py-2.5 text-primary hover:bg-primary/5 rounded-full transition-all duration-200"
            onClick={onClose}
          >
            <LogIn size={18} />
            <span>Sign In</span>
          </a>
          <Link 
            to="/pricing" 
            className="block"
            onClick={onClose}
          >
            <button className="w-full px-6 py-2.5 bg-primary text-white rounded-full hover:bg-white hover:text-primary border-2 border-primary transition-all duration-200">
              Get Started
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;