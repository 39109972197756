import React, { useEffect, useRef, useState } from 'react';

interface VideoPlayerProps {
  src: string;
  poster?: string;
  className?: string;
  alt: string;
}

export const VideoPlayer = ({ src, poster, className = "", alt }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoad = () => {
      console.log('Video loaded successfully:', alt);
      setIsLoaded(true);
      video.play().catch(err => {
        console.error('Error playing video:', err);
        setError('Failed to play video');
      });
    };

    const handleError = (e: Event) => {
      console.error('Error loading video:', e);
      setError('Failed to load video');
    };

    video.addEventListener('loadeddata', handleLoad);
    video.addEventListener('error', handleError);
    
    return () => {
      video.removeEventListener('loadeddata', handleLoad);
      video.removeEventListener('error', handleError);
    };
  }, [alt]);

  if (error) {
    return (
      <div className="bg-gray-50 rounded-lg p-4 text-center">
        <p className="text-gray-500">{error}</p>
      </div>
    );
  }

  return (
    <div className={`relative ${className}`}>
      {!isLoaded && (
        <div className="absolute inset-0 bg-gray-50 animate-pulse flex items-center justify-center">
          <span className="sr-only">Loading {alt}</span>
        </div>
      )}
      <video
        ref={videoRef}
        poster={poster}
        playsInline
        autoPlay
        muted
        loop
        className={`w-full h-auto ${isLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        aria-label={alt}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};