import { TabsList, TabsTrigger, Tabs } from "@/components/ui/tabs";

export const InfoTabs = () => {
  return (
    <Tabs defaultValue="contact" className="w-full">
      <div className="mb-16 bg-white">
        <TabsList className="h-14 grid w-full max-w-3xl grid-cols-3 mx-auto bg-primary/[0.03] rounded-full">
          <TabsTrigger 
            value="contact" 
            className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200 flex items-center justify-center"
            onClick={() => {
              const element = document.getElementById('contact');
              if (element) element.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Contact Us
          </TabsTrigger>
          <TabsTrigger 
            value="terms" 
            className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200 flex items-center justify-center"
            onClick={() => {
              const element = document.getElementById('terms');
              if (element) element.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Terms of Service
          </TabsTrigger>
          <TabsTrigger 
            value="privacy" 
            className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200 flex items-center justify-center"
            onClick={() => {
              const element = document.getElementById('privacy');
              if (element) element.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Privacy Policy
          </TabsTrigger>
        </TabsList>
      </div>
    </Tabs>
  );
};