import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface Feature {
  name: string;
  included: boolean;
}

export interface PricingTierType {
  id: string;
  name: string;
  price: number;
  description: string | null;
  features: Feature[];
  stripe_price_id: string;
  created_at: string;
}

const fetchPricingTiers = async () => {
  console.log('Fetching pricing tiers from Supabase...');
  const { data, error } = await supabase
    .from('stripe_products')
    .select('*')
    .order('price');
  
  if (error) {
    console.error('Error fetching pricing tiers:', error);
    throw error;
  }

  console.log('Received pricing tiers from Supabase:', data);

  return data.map(tier => ({
    ...tier,
    features: (tier.features as any[]).map(f => ({
      name: f.name as string,
      included: f.included as boolean
    }))
  }));
};

export const usePricingTiers = () => {
  return useQuery({
    queryKey: ['pricing-tiers'],
    queryFn: fetchPricingTiers,
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
  });
};