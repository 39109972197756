// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = "https://tvobjaknxvuktpyfrqez.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR2b2JqYWtueHZ1a3RweWZycWV6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzI3NDczNzksImV4cCI6MjA0ODMyMzM3OX0.RrErANsLTWnCJQKpbwwrFjXYtTlD6vrw99BUqxv0FkM";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(supabaseUrl, supabaseKey);