interface PricingDisplayProps {
  price: number;
}

export const PricingDisplay = ({ price }: PricingDisplayProps) => {
  return (
    <div className="mt-6">
      <div className="flex items-end gap-1">
        <span className="text-4xl font-bold">${price.toFixed(2)}</span>
        <span className="text-lg text-gray-500 group-hover:text-white/70 mb-1">/user/month</span>
      </div>
    </div>
  );
};