import { motion } from "framer-motion";
import { Calendar, Star, Zap, Rocket } from "lucide-react";

const roadmapItems = [
  {
    phase: "Q1 2025",
    title: "Intelligence & Customization",
    description: "AI-Generated Tags: Automatically tag and categorize images.\nPDF Templates: Design custom PDF layouts for project reports.\nGantt Charts: Generate Gantt charts from task management.",
    icon: <Calendar className="w-6 h-6" />,
    status: "in-progress"
  },
  {
    phase: "Q2 2025",
    title: "Connectivity & Multimedia",
    description: "OneDrive Integration: Sync and manage images directly from OneDrive.\nVideo Support: Add and view videos in project galleries.\nBefore-and-After Tool: Align and compare images for precise project showcases.",
    icon: <Star className="w-6 h-6" />,
    status: "planned"
  },
  {
    phase: "Q3 2025",
    title: "Showcasing & Client Engagement",
    description: "Project Showcases: Create polished galleries for websites using stored images.\nClient Portals: Secure client access for project updates.\nUsage Metrics: Track engagement and usage patterns across projects.",
    icon: <Zap className="w-6 h-6" />,
    status: "planned"
  },
  {
    phase: "Q4 2025",
    title: "Mobility & Flexibility",
    description: "iOS Development: Build a mobile app for on-the-go access.\nOffline Syncing: Work offline with automatic syncing.\nSlack and Asana Integrations: Connect tasks and notifications to popular team tools.",
    icon: <Rocket className="w-6 h-6" />,
    status: "planned"
  }
];

export const RoadmapSection = () => {
  return (
    <section className="py-16 bg-accent/20 scroll-mt-24">
      <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Product Roadmap</h2>
          <p className="text-lg text-primary/70 max-w-2xl mx-auto">
            We're constantly evolving. Here's what we're working on to make Property Vue even better.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-[85rem] mx-auto">
          {roadmapItems.map((item, index) => (
            <motion.div
              key={item.phase}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="relative h-full"
            >
              <div className="bg-white rounded-lg p-6 shadow-lg border border-primary/10 h-full flex flex-col transition-transform duration-300 ease-out hover:scale-[1.02] hover:shadow-xl">
                <div className="absolute -top-3 left-6 bg-primary text-white text-sm px-3 py-1 rounded-full">
                  {item.phase}
                </div>
                <div className="mt-4 flex-grow">
                  <div className="flex items-center gap-2 mb-4">
                    <div className="text-primary">
                      {item.icon}
                    </div>
                    <h3 className="text-lg font-semibold leading-tight">{item.title}</h3>
                  </div>
                  <div className="space-y-2">
                    {item.description.split('\n').map((line, i) => {
                      const [topic, description] = line.split(': ');
                      return (
                        <p key={i} className="text-sm leading-relaxed">
                          <span className="font-medium text-primary">{topic}:</span>
                          <span className="text-primary/70"> {description}</span>
                        </p>
                      );
                    })}
                  </div>
                  <div className="mt-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      item.status === 'in-progress' 
                        ? 'bg-green-100 text-green-700'
                        : 'bg-accent text-primary/70'
                    }`}>
                      {item.status === 'in-progress' ? 'In Progress' : 'Planned'}
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};