import { Link } from "react-router-dom";
import { ContactForm } from "../ContactForm";

export const ContactSection = () => {
  return (
    <section id="contact" className="scroll-mt-24">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="prose max-w-none space-y-6">
          <h3 className="text-2xl font-semibold text-primary">Contact Us</h3>
          <p className="text-lg">For any questions or issues, you can:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Email us directly at{" "}
              <a 
                href="mailto:help@propertyvue.io"
                className="text-primary hover:underline"
              >
                help@propertyvue.io
              </a>
            </li>
            <li>
              Visit our{" "}
              <Link 
                to="/#faq"
                className="text-primary hover:underline"
              >
                FAQ
              </Link>
              {" "}section for quick answers
            </li>
            <li>
              Fill out our support form, and we'll get back to you within 24 hours
            </li>
          </ul>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};