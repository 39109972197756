export const TermsSection = () => {
  return (
    <section id="terms" className="scroll-mt-24 mb-32">
      <h3 className="text-2xl font-semibold text-primary mb-6">Terms of Service</h3>
      <div className="prose max-w-none space-y-6">
        <p>Welcome to Property Vue! By creating an account, you agree to the following terms and conditions.</p>
        
        <div className="space-y-6">
          <div>
            <h4 className="font-semibold">1. Account Responsibilities</h4>
            <p>You are responsible for maintaining the accuracy of the information provided in your account and for ensuring the security of your login credentials. You must not use Property Vue for illegal activities, upload content that violates the rights of others, or engage in behavior that disrupts the platform's functionality or security.</p>
          </div>

          <div>
            <h4 className="font-semibold">2. Subscriptions and Billing</h4>
            <p>Paid subscriptions are billed monthly and renew automatically unless canceled. You may cancel or upgrade your subscription at any time through the account settings. Refunds are issued upon request; users can initiate a refund by contacting our support team.</p>
          </div>

          <div>
            <h4 className="font-semibold">3. Platform Changes</h4>
            <p>Property Vue reserves the right to add, modify, or discontinue features of the platform at any time. Users will be notified in advance of significant changes.</p>
          </div>

          <div>
            <h4 className="font-semibold">4. Data and Content Ownership</h4>
            <p>Users retain full ownership of the images and data they upload to Property Vue. We do not claim ownership or retain rights to your images. However, by uploading content, you grant Property Vue a non-exclusive, limited license to process and display your content solely for the purpose of providing the platform's services.</p>
          </div>

          <div>
            <h4 className="font-semibold">5. Law Enforcement Requests</h4>
            <p>Property Vue may be required to disclose user-uploaded content, including images, to comply with valid legal requests from law enforcement or governmental authorities. We will notify users where legally permissible if their data is requested.</p>
          </div>

          <div>
            <h4 className="font-semibold">6. Liability Disclaimer</h4>
            <p>Property Vue is not responsible for:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Structural or construction decisions made based on the platform's outputs or suggestions.</li>
              <li>Data loss, service interruptions, or user errors, including accidental deletion of images.</li>
              <li>Consequences arising from third-party integrations or tools used within the platform.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold">7. Third-Party Technology</h4>
            <p>Property Vue relies on trusted third-party services for critical functionality, including:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li><span className="font-medium">Supabase:</span> Secure database management and data storage through a dedicated integration with Property Vue.</li>
              <li><span className="font-medium">Netlify:</span> Hosting and reliable website performance provided through a direct integration with Property Vue.</li>
              <li><span className="font-medium">Google:</span> Email hosting, analytics, and integrations with Google Drive are enabled through direct integration with Property Vue.</li>
              <li><span className="font-medium">Dropbox:</span> File syncing and importing for user files through a direct integration with Property Vue.</li>
              <li><span className="font-medium">Stripe:</span> Secure payment processing for subscription billing is integrated directly into Property Vue.</li>
              <li><span className="font-medium">Twilio:</span> Enabling SMS integration for user notifications and functionality is powered through a direct integration with Property Vue.</li>
            </ul>
            
            <div className="mt-4">
              <p className="font-medium">Twilio Integration and SMS Opt-In</p>
              <p>To use SMS functionality powered by Twilio, users must:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Navigate to the integrations settings within Property Vue.</li>
                <li>Enter their phone number for SMS communications.</li>
                <li>Verify ownership via a unique verification code.</li>
                <li>Explicitly opt in by confirming consent to receive SMS communications.</li>
              </ul>
            </div>
          </div>

          <div>
            <h4 className="font-semibold">8. Updates to Terms of Service</h4>
            <p>Property Vue reserves the right to update these Terms of Service. Users will be notified via email at least 30 days before changes take effect.</p>
          </div>

          <div>
            <h4 className="font-semibold">9. Contact Information</h4>
            <p>For questions or concerns regarding these Terms of Service, please contact our support team.</p>
          </div>
        </div>
      </div>
    </section>
  );
};