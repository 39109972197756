import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { motion } from "framer-motion";
import { VideoPlayer } from "@/components/ui/VideoPlayer";
import { CommentImage } from "./features/CommentImage";
import { TaskDemo } from "./features/TaskDemo";

export const FeaturesSection = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMedia = async () => {
      setIsLoading(true);
      console.log("Fetching media URLs...");
      try {
        const [videoResult, imageResult] = await Promise.all([
          supabase.storage
            .from('media')
            .createSignedUrl('integrations.mp4', 60 * 60),
          supabase.storage
            .from('media')
            .createSignedUrl('commentsFeature.png', 60 * 60)
        ]);

        if (videoResult.data?.signedUrl) {
          console.log("Successfully got signed URL for video");
          setVideoUrl(videoResult.data.signedUrl);
        }
        
        if (imageResult.data?.signedUrl) {
          console.log("Successfully got signed URL for image");
          setImageUrl(imageResult.data.signedUrl);
        }
      } catch (err) {
        console.error("Failed to fetch media URLs:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMedia();
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <section id="features" className="py-20 bg-white">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-between gap-6">
          <Tabs defaultValue="integrations" className="w-full">
            <div className="mb-16 bg-white">
              <TabsList className="h-14 grid w-full max-w-3xl grid-cols-4 mx-auto bg-primary/[0.03] rounded-full">
                <TabsTrigger 
                  value="integrations" 
                  onClick={() => scrollToSection('cloud-integration')}
                  className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200"
                >
                  Cloud Integration
                </TabsTrigger>
                <TabsTrigger 
                  value="comments" 
                  onClick={() => scrollToSection('image-comments')}
                  className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200"
                >
                  Image Comments
                </TabsTrigger>
                <TabsTrigger 
                  value="tasks" 
                  onClick={() => scrollToSection('manage-tasks')}
                  className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200"
                >
                  Manage Tasks
                </TabsTrigger>
                <TabsTrigger 
                  value="reports" 
                  onClick={() => scrollToSection('generate-reports')}
                  className="h-full rounded-full data-[state=active]:bg-primary data-[state=active]:text-white hover:bg-primary/10 transition-all duration-200"
                >
                  Generate Reports
                </TabsTrigger>
              </TabsList>
            </div>
            
            <div className="space-y-32">
              {/* Cloud Integration - Media Right */}
              <div 
                id="cloud-integration" 
                className="flex flex-col md:flex-row items-center justify-between gap-16 scroll-mt-32"
              >
                <div className="w-full md:w-1/2">
                  <h2 className="text-2xl font-bold mb-4 text-left">Seamless Cloud Integration</h2>
                  <p className="text-base text-gray-600 text-left mb-4">
                    Capture images on-site with your smartphone and instantly sync them with Property Vue through popular cloud storage services like Google Drive and Dropbox.
                  </p>
                  <p className="text-base text-gray-600 text-left">
                    Our seamless integration ensures your project images are always organized and readily available, whether you're in the office or on-site.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  {isLoading ? (
                    <div className="w-full aspect-video bg-gray-50 animate-pulse rounded-lg flex items-center justify-center">
                      <span className="text-gray-400">Loading...</span>
                    </div>
                  ) : (
                    <VideoPlayer
                      src={videoUrl}
                      alt="Cloud integration demonstration"
                      className="w-full aspect-video rounded-lg"
                    />
                  )}
                </div>
              </div>

              {/* Image Comments - Media Left */}
              <div 
                id="image-comments" 
                className="flex flex-col md:flex-row-reverse items-center justify-between gap-16 scroll-mt-32"
              >
                <div className="w-full md:w-1/2">
                  <h2 className="text-2xl font-bold mb-4 text-left">Interactive Image Comments</h2>
                  <p className="text-base text-gray-600 text-left mb-4">
                    Add contextual comments directly on your construction site photos to highlight important details and communicate effectively with your team.
                  </p>
                  <p className="text-base text-gray-600 text-left">
                    Hover over the numbered pins to see detailed comments about specific areas of the construction site.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <CommentImage imageUrl={imageUrl} isLoading={isLoading} />
                </div>
              </div>

              {/* Tasks - Media Right */}
              <div 
                id="manage-tasks" 
                className="flex flex-col md:flex-row items-center justify-between gap-16 scroll-mt-32"
              >
                <div className="w-full md:w-1/2">
                  <h2 className="text-2xl font-bold mb-4 text-left">Streamlined Task Management</h2>
                  <p className="text-base text-gray-600 text-left mb-4">
                    Create and manage detailed tasks with attached images, making it easy to communicate requirements and track progress across your construction projects.
                  </p>
                  <p className="text-base text-gray-600 text-left">
                    Add multiple reference images, set due dates, and organize tasks by project for better coordination and clearer communication with your team.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <TaskDemo />
                </div>
              </div>

              {/* Reports - Media Left */}
              <div 
                id="generate-reports" 
                className="flex flex-col md:flex-row-reverse items-center justify-between gap-16 scroll-mt-32"
              >
                <div className="w-full md:w-1/2">
                  <h2 className="text-2xl font-bold mb-4 text-left">Comprehensive Reports</h2>
                  <p className="text-base text-gray-600 text-left mb-4">
                    Generate detailed reports with just a few clicks. Get insights into project progress, resource allocation, and timeline adherence.
                  </p>
                  <p className="text-base text-gray-600 text-left">
                    Export reports in multiple formats and share them easily with stakeholders to keep everyone informed about project developments.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <motion.img
                    src="/placeholder.svg"
                    alt="Report Generation Interface"
                    className="w-full rounded-lg shadow-lg"
                    initial={{ opacity: 0, scale: 0.9 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}
                  />
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};