export const PrivacySection = () => {
  return (
    <section id="privacy" className="scroll-mt-24">
      <h3 className="text-2xl font-semibold text-primary mb-6">Privacy Policy</h3>
      <div className="prose max-w-none space-y-6">
        <p>Property Vue values your privacy and is committed to protecting your personal data.</p>
        
        <div className="space-y-6">
          <div>
            <h4 className="font-semibold">1. Information Collection</h4>
            <p>We collect the following data:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Personal information such as your name, email address, and billing information for paid plans.</li>
              <li>Data from integrations with Google Drive and Dropbox to enable functionality within the platform.</li>
              <li>Usage data for analytics and service improvement.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold">2. Use of Data</h4>
            <p>Your data is used to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Provide and enhance the Property Vue platform.</li>
              <li>Process payments securely through Stripe.</li>
              <li>Enable integrations with third-party services such as Google Drive, Dropbox, and Twilio.</li>
              <li>Conduct internal analysis using tools like Google Analytics.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold">3. Data Sharing</h4>
            <p>Data may be shared with the following trusted third-party services:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Supabase: For secure data management and storage.</li>
              <li>Netlify: For platform hosting and performance.</li>
              <li>Google: For analytics and Drive integrations.</li>
              <li>Dropbox: For syncing and importing user files.</li>
              <li>Stripe: For payment processing.</li>
              <li>Twilio: For enabling SMS functionality.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold">4. User Rights</h4>
            <p>You have the right to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Access, correct, or delete your data by contacting support.</li>
              <li>Download all uploaded images and reports before account closure.</li>
              <li>Withdraw consent for optional integrations such as SMS functionality.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold">5. Data Security</h4>
            <p>We implement industry-standard security practices, including encryption and regular audits, to protect your data. Users are encouraged to follow best practices, such as using strong passwords and enabling two-factor authentication where possible.</p>
          </div>

          <div>
            <h4 className="font-semibold">6. Law Enforcement and Legal Compliance</h4>
            <p>Property Vue may disclose user data, including uploaded images, to comply with valid legal processes or law enforcement requests. We will notify users when permitted by law.</p>
          </div>

          <div>
            <h4 className="font-semibold">7. Data Retention</h4>
            <p>We retain user data for as long as necessary to provide the platform's services. Upon account deletion, all personal data and uploaded content will be permanently removed unless required by law.</p>
          </div>

          <div>
            <h4 className="font-semibold">8. Cookies and Tracking Technologies</h4>
            <p>Property Vue uses cookies and similar technologies to enhance the user experience and improve platform performance. You can manage your preferences through your browser settings.</p>
          </div>

          <div>
            <h4 className="font-semibold">9. Children's Privacy</h4>
            <p>Property Vue is not intended for use by children under the age of 13, and we do not knowingly collect data from minors.</p>
          </div>

          <div>
            <h4 className="font-semibold">10. Updates to Privacy Policy</h4>
            <p>Property Vue may update this Privacy Policy. Users will be notified via email at least 30 days before changes take effect.</p>
          </div>

          <div>
            <h4 className="font-semibold">11. Contact Information</h4>
            <p>For questions or concerns about this Privacy Policy, please contact our support team.</p>
          </div>
        </div>
      </div>
    </section>
  );
};