import { Link, useLocation } from "react-router-dom";

interface NavLinkProps {
  href: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const NavLink = ({ href, onClick, children }: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === href;
  
  return (
    <Link
      to={href}
      onClick={onClick}
      className={`relative px-4 py-2 rounded-full transition-all duration-200 ${
        isActive 
          ? "bg-primary text-white" 
          : "text-primary/70 hover:bg-primary/10"
      }`}
    >
      {children}
    </Link>
  );
};

export default NavLink;