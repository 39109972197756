import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex-shrink-0">
      <Link to="/" className="block">
        <picture>
          <img
            src="/lovable-uploads/6b2989bd-450b-4039-994f-736a4d8cf30c.png"
            alt="Property Vue Logo"
            className="h-12 w-auto"
            width={48}
            height={48}
            loading="eager"
            decoding="async"
            fetchPriority="high"
          />
        </picture>
      </Link>
    </div>
  );
};

export default Logo;