import { motion } from "framer-motion";

export const FaqFooter = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.4 }}
      className="text-center mt-8"
    >
      <p className="text-gray-600">
        Still have questions?{" "}
        <a
          href="mailto:help@propertyvue.io"
          className="text-primary font-semibold hover:underline"
        >
          Contact our support team
        </a>
      </p>
    </motion.div>
  );
};