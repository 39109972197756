import { ChevronRight } from "lucide-react";
import { DemoRequestForm } from "../DemoRequestForm";
import { supabase } from "@/integrations/supabase/client";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { VideoPlayer } from "../ui/VideoPlayer";

export const HeroSection = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      setIsLoading(true);
      console.log("Fetching hero video URL...");
      try {
        const { data: publicUrlData, error } = await supabase.storage
          .from('media')
          .createSignedUrl('hero.mp4', 60 * 60 * 24);

        if (error) {
          console.error("Error getting signed URL:", error);
          return;
        }

        if (publicUrlData?.signedUrl) {
          console.log("Setting hero video URL:", publicUrlData.signedUrl);
          setVideoUrl(publicUrlData.signedUrl);
          setIsLoading(false);
        } else {
          console.log("No signed URL found");
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Failed to fetch video URL:", err);
        setIsLoading(false);
      }
    };

    fetchVideoUrl();
  }, []);

  return (
    <section className="pt-32 md:pt-40 pb-20 md:pb-32 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
          <div className="text-left space-y-6 md:space-y-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-primary">
              Every image, in one place, for every project.
            </h1>
            <p className="text-lg md:text-xl text-primary/70 max-w-2xl">
              Organize all your job site images into one platform, making them easy to find, share, and use.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link to="/pricing" className="w-full sm:w-auto">
                <Button 
                  size="lg" 
                  className="w-full sm:w-auto px-6 md:px-8 py-3 rounded-xl bg-primary text-white hover:bg-white hover:text-primary border-2 border-primary transition-all duration-300 flex items-center justify-center"
                >
                  Start Free Trial
                  <ChevronRight className="ml-2" size={20} />
                </Button>
              </Link>
              <DemoRequestForm />
            </div>
          </div>
          <div className="flex justify-center md:justify-end mt-8 md:mt-0">
            {isLoading ? (
              <div className="w-full aspect-video bg-gray-50 animate-pulse flex items-center justify-center">
                <span className="text-gray-400">Loading...</span>
              </div>
            ) : videoUrl ? (
              <div className="w-full max-w-[600px]">
                <VideoPlayer
                  src={videoUrl}
                  alt="Hero animation"
                  className="w-full animate-fade-in"
                />
              </div>
            ) : (
              <div className="w-full aspect-video bg-gray-50 flex items-center justify-center">
                <span className="text-gray-400">Failed to load video</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};