import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";

interface FaqItemProps {
  question: string;
  answer: string;
  index: number;
}

export const FaqItem = ({ question, answer, index }: FaqItemProps) => {
  return (
    <AccordionItem
      value={`item-${index}`}
      className="group bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300"
    >
      <AccordionTrigger 
        className="text-base font-medium hover:no-underline px-4 py-3 data-[state=open]:text-primary group-hover:text-primary/80 transition-all duration-300 ease-in-out"
      >
        {question}
      </AccordionTrigger>
      <AccordionContent 
        className="text-sm text-gray-600 px-4 leading-relaxed transition-all duration-300 ease-in-out data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      >
        {answer}
      </AccordionContent>
    </AccordionItem>
  );
};