import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

interface StepCardProps {
  step: {
    number: string;
    title: string;
    description: string;
  };
  index: number;
  activeStep: number;
  onClick: () => void;
}

export const StepCard = ({ step, index, activeStep, onClick }: StepCardProps) => {
  const isInactive = index > activeStep;
  
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
      }}
      className={cn(
        "relative cursor-pointer h-full",
        isInactive && "cursor-not-allowed"
      )}
      onClick={onClick}
    >
      {isInactive && (
        <div className="absolute inset-0 bg-primary/20 rounded-xl animate-pulse-scale" />
      )}
      <div 
        className={cn(
          "h-full bg-white rounded-xl p-8 transition-all duration-500 relative z-10",
          isInactive && "blur-[4px]",
          "border-2",
          index === activeStep ? "border-primary shadow-lg" : "border-accent"
        )}
      >
        <div className="flex items-center gap-4 mb-4">
          <div 
            className={cn(
              "w-12 h-12 rounded-full flex items-center justify-center text-xl font-bold",
              index <= activeStep ? "bg-primary text-white" : "bg-accent text-primary"
            )}
          >
            {step.number}
          </div>
          <h3 className="text-xl font-semibold text-primary">
            {step.title}
          </h3>
        </div>
        <p className="text-primary/70">
          {step.description}
        </p>
      </div>
    </motion.div>
  );
};