import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { useToast } from "./ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

export const DemoRequestForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Submitting demo request form...");

    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get("name") as string,
      email: formData.get("email") as string,
      phone_number: formData.get("phone") as string,
      company: formData.get("company") as string,
    };

    try {
      const { error: insertError } = await supabase
        .from("demo_requests")
        .insert([data]);
      
      if (insertError) throw insertError;

      // Send email notification
      const { error: notificationError } = await supabase.functions.invoke('send-demo-notification', {
        body: { record: data }
      });

      if (notificationError) {
        console.error("Error sending notification:", notificationError);
        // Don't throw here - we still want to show success for the demo request
      }

      toast({
        title: "Success!",
        description: "Your demo request has been submitted. We'll be in touch soon!",
      });
      
      setOpen(false);
    } catch (error) {
      console.error("Error submitting demo request:", error);
      toast({
        title: "Error",
        description: "There was a problem submitting your request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="lg" className="w-full sm:w-auto px-8 py-3 rounded-xl bg-white text-primary border-2 border-primary hover:bg-primary hover:text-white transition-all duration-300 flex items-center justify-center min-w-[160px]">
          Request Demo
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-white border-none">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-primary">Request a Demo</DialogTitle>
          <DialogDescription className="text-gray-600">
            Fill out this form and we'll get back to you within 24 hours.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div>
            <Input
              name="name"
              placeholder="Your Name"
              required
              className="w-full bg-white"
            />
          </div>
          <div>
            <Input
              name="email"
              type="email"
              placeholder="Email Address"
              required
              className="w-full bg-white"
            />
          </div>
          <div>
            <Input
              name="phone"
              type="tel"
              placeholder="Phone Number (Optional)"
              className="w-full bg-white"
            />
          </div>
          <div>
            <Input
              name="company"
              placeholder="Company Name (Optional)"
              className="w-full bg-white"
            />
          </div>
          <Button
            type="submit"
            className="w-full bg-primary text-white hover:bg-primary/90"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit Request"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};