import { motion } from "framer-motion";
import { PricingTier } from "./PricingTier";
import type { PricingTierType } from "@/hooks/usePricingTiers";

interface PricingGridProps {
  pricingTiers: PricingTierType[];
}

const PricingGrid = ({ pricingTiers }: PricingGridProps) => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="mt-16 max-w-7xl mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
    >
      {pricingTiers?.map((tier, index) => (
        <PricingTier 
          key={tier.id}
          name={tier.name}
          price={tier.price}
          description={tier.description}
          features={tier.features}
          stripe_price_id={tier.stripe_price_id}
          isPopular={index === 1}
        />
      ))}
    </motion.div>
  );
};

export default PricingGrid;