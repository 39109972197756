import { motion } from "framer-motion";

export const StepHeader = () => {
  return (
    <div className="text-center">
      <h2 className="text-4xl font-bold text-primary">How It Works</h2>
      <p className="mt-4 text-lg text-primary/70 max-w-2xl mx-auto">
        Get started with Property Vue in three simple steps
      </p>
    </div>
  );
};