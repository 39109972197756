import { PortableText } from "@portabletext/react";
import { urlFor } from "@/lib/sanity";

interface BlogPostContentProps {
  title: string;
  publishedAt: string;
  image?: {
    asset: {
      _ref: string;
    };
  };
  body: any[];
}

const BlogPostContent = ({ title, publishedAt, image, body }: BlogPostContentProps) => {
  console.log('BlogPostContent received props:', { title, publishedAt, image });
  console.log('Body content structure:', JSON.stringify(body, null, 2));
  
  return (
    <article className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-4">{title}</h1>
      <p className="text-gray-500 mb-6">
        {new Date(publishedAt).toLocaleDateString()}
      </p>
      {image && (
        <img
          src={urlFor(image)
            .width(1000)  /* Increased from 800 */
            .height(400)  /* Reduced from 600 */
            .quality(90)
            .fit('crop')
            .crop('entropy')
            .url()}
          alt={title}
          className="w-full rounded-lg mb-8"
        />
      )}
      <div className="prose prose-lg max-w-none">
        <PortableText 
          value={body}
        />
      </div>
    </article>
  );
};

export default BlogPostContent;