import { motion } from "framer-motion";

export const FaqHeader = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="text-center mb-12"
    >
      <h2 className="text-4xl font-bold mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-gray-600 max-w-2xl mx-auto">
        Find answers to common questions about Property Vue's features and capabilities
      </p>
    </motion.div>
  );
};