import { motion } from "framer-motion";
import { CommentPin } from "../integration/comments/CommentPin";
import { Skeleton } from "@/components/ui/skeleton";

const comments = [
  {
    id: 1,
    x: 20,
    y: 25,
    text: "Pin comments anywhere on images",
  },
  {
    id: 2,
    x: 75,
    y: 30,
    text: "Add detailed text for clarity",
  },
  {
    id: 3,
    x: 30,
    y: 70,
    text: "See exactly who commented where",
  },
  {
    id: 4,
    x: 80,
    y: 65,
    text: "Comments stay linked to tasks",
  },
];

interface CommentImageProps {
  imageUrl: string;
  isLoading: boolean;
}

export const CommentImage = ({ imageUrl, isLoading }: CommentImageProps) => {
  if (isLoading) {
    return <Skeleton className="w-[85%] mx-auto aspect-video rounded-lg" />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className="relative w-[85%] mx-auto rounded-lg overflow-hidden shadow-lg"
    >
      <img
        src={imageUrl}
        alt="Construction site with interactive comments"
        className="w-full h-auto"
        loading="eager"
        decoding="async"
        onError={(e) => {
          e.currentTarget.src = "/placeholder.svg";
        }}
      />
      {comments.map((comment) => (
        <CommentPin key={comment.id} {...comment} />
      ))}
    </motion.div>
  );
};