import { Check, X } from "lucide-react";

interface PricingFeatureItemProps {
  name: string;
  included: boolean;
}

export const PricingFeatureItem = ({ name, included }: PricingFeatureItemProps) => {
  return (
    <li className="flex items-start gap-3">
      {included ? (
        <Check className="h-5 w-5 text-green-500 group-hover:text-green-400 mt-0.5 shrink-0" />
      ) : (
        <X className="h-5 w-5 text-red-500 group-hover:text-red-400 mt-0.5 shrink-0" />
      )}
      <span>{name}</span>
    </li>
  );
};