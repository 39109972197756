import { motion } from "framer-motion";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { StepCard } from "./howItWorks/StepCard";
import { StepHeader } from "./howItWorks/StepHeader";

const steps = [
  {
    number: "01",
    title: "Create a Workspace",
    description: "Set up your workspace to organize all your images and collaborate with your team.",
  },
  {
    number: "02",
    title: "Create a Project",
    description: "Create projects for each jobsite or property to keep your images organized and accessible.",
  },
  {
    number: "03",
    title: "Start Adding Images",
    description: "Upload and organize your images by project and tags. Create Tasks, Reports, and start collaborating with your team.",
  },
];

export const HowItWorksSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleStepClick = (index: number) => {
    if (index <= activeStep + 1) {
      setActiveStep(index);
    }
  };

  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={{
            hidden: {},
            visible: {
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
          className="space-y-16"
        >
          <StepHeader />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <StepCard
                key={step.number}
                step={step}
                index={index}
                activeStep={activeStep}
                onClick={() => handleStepClick(index)}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};
