import { useQuery } from "@tanstack/react-query";
import Navigation from "../components/Navigation";
import { Footer } from "../components/sections/Footer";
import { client } from "../lib/sanity";
import BlogGrid from "@/components/blog/BlogGrid";

interface BlogPost {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  publishedAt: string;
  image: {
    asset: {
      _ref: string;
    };
  };
  body: Array<{
    children: Array<{
      text: string;
    }>;
  }>;
}

const Blog = () => {
  const { data: posts, isLoading, error } = useQuery({
    queryKey: ['blog-posts'],
    queryFn: async () => {
      console.log('Fetching blog posts from Sanity');
      const result = await client.fetch<BlogPost[]>(`
        *[_type == "post"] | order(publishedAt desc) {
          _id,
          title,
          slug,
          publishedAt,
          image,
          body
        }
      `);
      console.log('Fetched posts:', result);
      return result;
    }
  });

  if (error) {
    console.error('Error fetching blog posts:', error);
  }

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <BlogGrid posts={posts} isLoading={isLoading} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;